import { combineReducers } from 'redux';
import CustomizerReducer from './customizer/CustomizerReducer';
import adminReducer from './reducers/AdminReducer';
import artistReducer from './reducers/ArtistReducer';
import collectionReducer from './reducers/CollectionReducer';
import logReducer from './reducers/LogReducer';
import systemReducer from './reducers/SystemReducer';
import userReducer from './reducers/UserReducer';

const RootReducers = combineReducers({
  CustomizerReducer,
  admin: adminReducer,
  artist: artistReducer,
  collection: collectionReducer,
  log: logReducer,
  system: systemReducer,
  user: userReducer,
});

export default RootReducers;
