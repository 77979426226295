import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIs from '../../api/APIs';

const adminState = {
  list: []
};

export const fetchAdmin = createAsyncThunk(
  'admin/fetchAdmin',
  async (_, thunkAPI) => {
    try {
      const response = await APIs.fethAdminList();
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue('Something went wrong.');
    }
  }
);

export const slice = createSlice({
  name: 'admin',
  initialState: adminState,
  reducers: {
    addAdmin: (state, action) => {
      const defaultConfig = {
        id: 0,
        admin: 0,
        chargeof: '',
        collection: 0,
        created_at: '',
        email: '',
        log: 0,
        name: '',
        phone: '',
        position: '',
        status: 'stable',
        system: 0,
        team: '',
        update_at: '',
        user: 0,
      }

      state.list.unshift({
        ...defaultConfig,
        ...action.payload
      });
    },
    setAuth: (state, action) => {
      const { id, auth } = action.payload;
      const targetIndex = state.list.findIndex(item => item.id === id);
      const target = state.list[targetIndex];

      state.list[targetIndex] = {
        ...target,
        ...auth
      }
    },
    changeAdminData: (state, action) => {
      const { id, key, value } = action.payload;
      const index = state.list.findIndex(item => item.id === id);
      state.list[index] = {
        ...state.list[index],
        [key]: value
      }
    },
  },
  extraReducers: {
    [fetchAdmin.fulfilled.type]: (state, action) => {
      state.list = action.payload;
    },
    [fetchAdmin.rejected.type]: (state, _action) => {
      state.list = [];
    },
  },
});

export const {
  addAdmin,
  setAuth,
  changeAdminData
} = slice.actions;

const AdminReducer = slice.reducer;
export default AdminReducer;
