import { isDev } from '../constants/NodeEnv';
import { get, patch, post } from './index';

const host = isDev ? 'https://apibeta.meta-rare.net' : 'https://api.meta-rare.net';

// 관리자
const fethAdminList = () => {
  const url = `${host}/v1/admin/administrator`;

  return get(url);
}

const registerAdmin = ({ chargeof, email, name, password, phone, position, team }) => {
  const url = `${host}/v1/admin/administrator`;

  return post(url, { chargeof, email, name, password, phone, position, team });
}

const changeAuth = ({ admin_id, admin_permission, collection_permission, log_permission, system_permission, user_permission }) => {
  const url = `${host}/v1/admin/administrator/permission`;

  return patch(url, { admin_id, admin_permission, collection_permission, log_permission, system_permission, user_permission });
}

const changeAdminStatus = ({ id, status }) => {
  const url = `${host}/v1/admin/administrator/status`;

  return patch(url, { id, status });
}

// 작가
const fetchArtist = () => {
  const url = `${host}/v1/admin/artist`;

  return get(url);
}

const registerArtist = ({ category, email, name, phone, team }) => {
  const url = `${host}/v1/admin/artist`;

  return post(url, { category, email, name, phone, team });
}

const changeArtistStatus = ({ id, status }) => {
  const url = `${host}/v1/admin/artist/status`;

  return patch(url, { id, status });
}


// 컬렉션
const fetchCollection = () => {
  const url = `${host}/v1/admin/collection`;

  return get(url);
}

const changeCollectionStatus = ({ id, status }) => {
  const url = `${host}/v1/admin/collection/status`;

  return patch(url, { id, status });
}

const changeCollectionCuration = ({ id, index }) => {
  const url = `${host}/v1/admin/collection/curation`;

  return patch(url, { id, index });
}

// 시스템
const fetchSystem = () => {
  const url = `${host}/v1/admin/setting`;

  return get(url);
}

const changeSystem = ({ type, value }) => {
  const url = `${host}/v1/admin/setting/${type}`;

  return patch(url, {
    [type]: value
  });
}

// 유저
const fetchUser = () => {
  const url = `${host}/v1/admin/user`;

  return get(url);
}

const changeUserStatus = ({ id, status }) => {
  const url = `${host}/v1/admin/user/status`;

  return patch(url, { id, status });
}

// 로그
const fetchLog = () => {
  const url = `${host}/v1/admin/log`;

  return get(url);
}

const signin = async (email, password) => {
  const url = `${host}/v1/admin/auth/signin`;

  return post(url, { email, password });
};

const APIs = {
  signin,


  fethAdminList,
  registerAdmin,
  changeAuth,
  changeAdminStatus,

  fetchArtist,
  registerArtist,
  changeArtistStatus,

  fetchCollection,
  changeCollectionStatus,
  changeCollectionCuration,

  fetchSystem,
  changeSystem,

  fetchLog,

  fetchUser,
  changeUserStatus
};

export default APIs;
