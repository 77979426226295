import { configureStore } from '@reduxjs/toolkit';
import RootReducers from './Rootreducers';

const devTools =
  process.env.NODE_ENV === 'development' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f;

const store = configureStore({
  reducer: RootReducers,
  devTools,
});

export default store;
