import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIs from '../../api/APIs';

const userState = {
  list: []
};

export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  async (_, thunkAPI) => {
    try {
      const response = await APIs.fetchUser();
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue('Something went wrong.');
    }
  }
);

export const slice = createSlice({
  name: 'user',
  initialState: userState,
  reducers: {
    changeUserData: (state, action) => {
      const { id, key, value } = action.payload;
      const index = state.list.findIndex(item => item.id === id);
      state.list[index] = {
        ...state.list[index],
        [key]: value
      }
    }
  },
  extraReducers: {
    [fetchUser.fulfilled.type]: (state, action) => {
      state.list = action.payload;
    },
    [fetchUser.rejected.type]: (state, _action) => {
      state.list = [];
    },
  },
});

export const {
  changeUserData
} = slice.actions;

const UserReducer = slice.reducer;
export default UserReducer;
