import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import URLInfo from '../constants/URLInfo';

/* ***Layouts**** */
const FullLayout = lazy(() => import('../layouts/full-layout/FullLayout'));
const BlankLayout = lazy(() => import('../layouts/blank-layout/BlankLayout'));

const Error = lazy(() => import('../views/authentication/Error'));
const Login = lazy(() => import('../views/authentication/Login'));
const Home = lazy(() => import('../views/metarare/Home'));
const User = lazy(() => import('../views/metarare/User'));
const Artist = lazy(() => import('../views/metarare/Artist'));
const Collection = lazy(() => import('../views/metarare/Collection'));
const System = lazy(() => import('../views/metarare/System'));
const Admin = lazy(() => import('../views/metarare/Admin'));
const Log = lazy(() => import('../views/metarare/Log'));

const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: URLInfo.HOME, exact: true, element: <Home /> },
      { path: URLInfo.USER, exact: true, element: <User /> },
      { path: URLInfo.ARTIST, exact: true, element: <Artist /> },
      { path: URLInfo.COLLECTION, exact: true, element: <Collection /> },
      { path: URLInfo.SYSTEM, exact: true, element: <System /> },
      { path: URLInfo.ADMIN, exact: true, element: <Admin /> },
      { path: URLInfo.LOG, exact: true, element: <Log /> },
      { path: '*', element: <Navigate to={URLInfo.NOT_FOUND} /> },
    ],
  },
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { path: URLInfo.NOT_FOUND, element: <Error /> },
      { path: URLInfo.LOGIN, element: <Login /> },
      { path: '*', element: <Navigate to={URLInfo.NOT_FOUND} /> },
    ],
  },
];

export default Router;
