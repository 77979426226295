import { isDev, frontDevHost, frontRealHost } from "./NodeEnv";

const URL = {
  HOME: '/',
  USER: '/member/user',
  ARTIST: '/member/artist',
  COLLECTION: '/collection',
  SYSTEM: '/system',
  ADMIN: '/admin',
  LOG: '/log',
  LOGIN: '/auth/login',
  NOT_FOUND: '/auth/404'
};

const SEARCH_PARAM = {
  RETURN_URL: 'returnUrl'
};

const getLoginURL = (returnUrl) => `${URL.LOGIN}?${SEARCH_PARAM.RETURN_URL}=${returnUrl}`;

const getUserProfile = (name) => isDev ? `${frontDevHost}/profile/${name}` : `${frontRealHost}/profile/${name}`;

const getCollectionProfile = (name) => isDev ? `${frontDevHost}/collection/${name}` : `${frontRealHost}/collection/${name}`;

export default {
  ...URL,
  SEARCH_PARAM,
  getLoginURL,
  getUserProfile,
  getCollectionProfile,
}