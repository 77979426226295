import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIs from '../../api/APIs';

const logState = {
  list: []
};

export const fetchLog = createAsyncThunk(
  'log/fetchLog',
  async (_, thunkAPI) => {
    try {
      const response = await APIs.fetchLog();
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue('Something went wrong.');
    }
  }
);

export const slice = createSlice({
  name: 'log',
  initialState: logState,
  reducers: {
    changeLogData: (state, action) => {
      const { id, key, value } = action.payload;
      const index = state.list.findIndex(item => item.id === id);
      state.list[index] = {
        ...state.list[index],
        [key]: value
      }
    },
  },
  extraReducers: {
    [fetchLog.fulfilled.type]: (state, action) => {
      state.list = action.payload;
    },
    [fetchLog.rejected.type]: (state, _action) => {
      state.list = [];
    },
  },
});

export const {
  changeLogData
} = slice.actions;

const LogReducer = slice.reducer;
export default LogReducer;
