import { AUTH_INFO } from "./SessionStorage";

class _AuthInfo {
  _authInfo;

  constructor(info) {
    this._authInfo = info;
  }

  setAuthInfo(info) {
    this._authInfo = info;
  }

  getAuthInfo() {
    return this._authInfo;
  }

  getAccessToken() {
    return this._authInfo?.access_token;
  }

  getPermission() {
    return this._authInfo?.permission;
  }

  getEmail() {
    return this._authInfo?.email;
  }
}

export default new _AuthInfo(sessionStorage.getItem(AUTH_INFO));