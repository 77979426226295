import axios from 'axios';
import AuthInfo from '../constants/AuthInfo';
import URLInfo from '../constants/URLInfo';

const isLoginPage = window.location.pathname === URLInfo.LOGIN;

export const get = async (url, params = {}, headers = {}) => {
  try {
    const result = await axios.get(url, {
      ...params,
      headers: {
        ...headers,
        'Authorization': `Bearer ${AuthInfo.getAccessToken()}`
      },
    });
    const { data } = result;

    return data;
  } catch (e) {
    if (!isLoginPage && e.response.status === 401) {
      window.location.href = URLInfo.getLoginURL(window.location.href);
    }
    throw e;
  }
};

export const post = async (url, body = {}, params = {}, headers = {}) => {
  try {
    const result = await axios.post(url, body, {
      ...params,
      headers: {
        ...headers,
        'Authorization': `Bearer ${AuthInfo.getAccessToken()}`
      },
    });
    const { data } = result;

    return data;
  } catch (e) {
    if (!isLoginPage && e.response.status === 401) {
      window.location.href = URLInfo.getLoginURL(window.location.href);
    }
    throw e;
  }
};

export const patch = async (url, body = {}, params = {}, headers = {}) => {
  try {
    const result = await axios.patch(url, body, {
      ...params,
      headers: {
        ...headers,
        'Authorization': `Bearer ${AuthInfo.getAccessToken()}`
      },
    });
    const { data } = result;

    return data;
  } catch (e) {
    if (!isLoginPage && e.response.status === 401) {
      window.location.href = URLInfo.getLoginURL(window.location.href);
    }
    throw e;
  }
};