import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider, useDispatch } from 'react-redux';
import App from './App';
import store from './redux/Store';
import './data';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import Spinner from './views/spinner/Spinner';
import { AUTH_INFO } from './constants/SessionStorage';
import URLInfo from './constants/URLInfo';
import AuthInfo from './constants/AuthInfo';

const Main = () => {
  useEffect(() => {
    const authInfo = JSON.parse(sessionStorage.getItem(AUTH_INFO));
    const isLoginPage = window.location.pathname === URLInfo.LOGIN;

    if (authInfo) {
      AuthInfo.setAuthInfo(authInfo);
    } else if (!isLoginPage) {
      window.location.href = URLInfo.getLoginURL(window.location.href);
    }
  }, []);

  return (
    <Suspense fallback={<Spinner />}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Suspense>
  )
}

ReactDOM.render(
  <Provider store={store}>
    <Main />
  </Provider>
  , document.getElementById('root'));

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
