import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIs from '../../api/APIs';

const artistState = {
  list: []
};

export const fetchArtist = createAsyncThunk(
  'artist/fetchArtist',
  async (_, thunkAPI) => {
    try {
      const response = await APIs.fetchArtist();
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue('Something went wrong.');
    }
  }
);

export const slice = createSlice({
  name: 'artist',
  initialState: artistState,
  reducers: {
    addArtist: (state, action) => {
      const defaultConfig = {
        id: 0,
        address: '',
        check_sum: '',
        created_at: '',
        email: '',
        enc_version: 0,
        name: '',
        phone: '',
        status: 'stable',
        team: '',
      }

      state.list.unshift({
        ...defaultConfig,
        ...action.payload
      });
    },
    changeArtistData: (state, action) => {
      const { id, key, value } = action.payload;
      const index = state.list.findIndex(item => item.id === id);
      state.list[index] = {
        ...state.list[index],
        [key]: value
      }
    }
  },
  extraReducers: {
    [fetchArtist.fulfilled.type]: (state, action) => {
      state.list = action.payload;
    },
    [fetchArtist.rejected.type]: (state, _action) => {
      state.list = [];
    },
  },
});

export const {
  addArtist,
  changeArtistData
} = slice.actions;

const ArtistReducer = slice.reducer;
export default ArtistReducer;
