import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIs from '../../api/APIs';

const systemState = {
  data: null
};

export const fetchSystem = createAsyncThunk(
  'system/fetchSystem',
  async (_, thunkAPI) => {
    try {
      const response = await APIs.fetchSystem();
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue('Something went wrong.');
    }
  }
);

export const slice = createSlice({
  name: 'system',
  initialState: systemState,
  reducers: {
    setSystem: (state, action) => {
      const { type, value } = action.payload;

      state.data = {
        ...state.data,
        [type]: value,
      }
    }
  },
  extraReducers: {
    [fetchSystem.fulfilled.type]: (state, action) => {
      state.data = action.payload;
    },
    [fetchSystem.rejected.type]: (state, _action) => {
      state.data = null;
    },
  },
});

export const {
  setSystem
} = slice.actions;

const SystemReducer = slice.reducer;
export default SystemReducer;
