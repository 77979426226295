import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import APIs from '../../api/APIs';

const collectionState = {
  list: []
};

export const fetchCollection = createAsyncThunk(
  'collection/fetchCollection',
  async (_, thunkAPI) => {
    try {
      const response = await APIs.fetchCollection();
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue('Something went wrong.');
    }
  }
);

export const slice = createSlice({
  name: 'collection',
  initialState: collectionState,
  reducers: {
    changeCollectionData: (state, action) => {
      const { id, key, value } = action.payload;
      const index = state.list.findIndex(item => item.id === id);
      state.list[index] = {
        ...state.list[index],
        [key]: value
      }
    },
    updateCurationNumber: (state, action) => {
      const curationIndex = action.payload;

      state.list.forEach((item, index) => {
        if (item.curating_number === curationIndex) {
          state.list[index].curating_number = 0;
        }
      })
    }
  },
  extraReducers: {
    [fetchCollection.fulfilled.type]: (state, action) => {
      state.list = action.payload;
    },
    [fetchCollection.rejected.type]: (state, _action) => {
      state.list = [];
    },
  },
});

export const {
  changeCollectionData,
  updateCurationNumber
} = slice.actions;

const CollectionReducer = slice.reducer;
export default CollectionReducer;
